import React from 'react'
import {Link, graphql} from 'gatsby'
import {kebabCase} from 'lodash'
import {GatsbyImage} from 'gatsby-plugin-image'

import DefaultLayout from '../components/layout'
import SEO from '../components/seo'

import 'katex/dist/katex.min.css'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const next = this.props.pageContext.next
    const previous = this.props.pageContext.previous

    if (next == null) {
      return (
          <DefaultLayout>
            <SEO title={post.frontmatter.title} description={post.excerpt}/>
            <div className="clearfix post-content-box">
              <article className="article-page">
                <div className="page-content">
                  {post.frontmatter.img && (
                      <div className="page-cover-image">
                        <figure>
                          <GatsbyImage
                              image={
                                post.frontmatter.img.childImageSharp.gatsbyImageData
                              }
                              className="page-image"
                              key={
                                post.frontmatter.img.childImageSharp.gatsbyImageData.src
                              }
                              alt=""
                          />
                        </figure>
                      </div>
                  )}
                  <div className="wrap-content">
                    <header className="header-page">
                      <h1 className="page-title">{post.frontmatter.title}</h1>
                      <div className="page-date">
                        <span>{post.frontmatter.date}</span>
                      </div>
                    </header>
                    <div dangerouslySetInnerHTML={{__html: post.html}}/>

                    <div className="next-previous">
                      <div className="previous">
                        <Link to={previous.fields.slug} style={{maxWidth: '25%'}}>
                          <strong>Previous Article</strong> <br/>
                          {previous.frontmatter.title}
                        </Link>
                        <Link className="img-link" to={previous.fields.slug}>
                          <img src={previous.frontmatter.img.childImageSharp.gatsbyImageData.images.fallback.src}/>
                        </Link>
                      </div>
                    </div>

                    <div className="page-footer">
                      <div className="page-tag">
                        {post.frontmatter.tags &&
                        post.frontmatter.tags.map((tag) => (
                            <span key={tag}>
                          <Link className="tag" to={`/tags/${kebabCase(tag)}/`}>
                            # {tag}
                          </Link>
                        </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </DefaultLayout>
      )
    } else if (previous == null) {
      return (
          <DefaultLayout>
            <SEO title={post.frontmatter.title} description={post.excerpt}/>
            <div className="clearfix post-content-box">
              <article className="article-page">
                <div className="page-content">
                  {post.frontmatter.img && (
                      <div className="page-cover-image">
                        <figure>
                          <GatsbyImage
                              image={
                                post.frontmatter.img.childImageSharp.gatsbyImageData
                              }
                              className="page-image"
                              key={
                                post.frontmatter.img.childImageSharp.gatsbyImageData.src
                              }
                              alt=""
                          />
                        </figure>
                      </div>
                  )}
                  <div className="wrap-content">
                    <header className="header-page">
                      <h1 className="page-title">{post.frontmatter.title}</h1>
                      <div className="page-date">
                        <span>{post.frontmatter.date}</span>
                      </div>
                    </header>
                    <div dangerouslySetInnerHTML={{__html: post.html}}/>

                    <div className="next-previous">
                      <div className="next">
                        <Link to={next.fields.slug}>
                          <strong>Next Article</strong> <br/>
                          {next.frontmatter.title}
                        </Link>
                        <Link className="img-link" to={next.fields.slug}>
                          <img src={next.frontmatter.img.childImageSharp.gatsbyImageData.images.fallback.src}/>
                        </Link>
                      </div>
                    </div>
                    <div className="page-footer">
                      <div className="page-tag">
                        {post.frontmatter.tags &&
                        post.frontmatter.tags.map((tag) => (
                            <span key={tag}>
                          <Link className="tag" to={`/tags/${kebabCase(tag)}/`}>
                            # {tag}
                          </Link>
                        </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </DefaultLayout>
      )
    } else {
      return (
          <DefaultLayout>
            <SEO title={post.frontmatter.title} description={post.excerpt}/>
            <div className="clearfix post-content-box">
              <article className="article-page">
                <div className="page-content">
                  {post.frontmatter.img && (
                      <div className="page-cover-image">
                        <figure>
                          <GatsbyImage
                              image={
                                post.frontmatter.img.childImageSharp.gatsbyImageData
                              }
                              className="page-image"
                              key={
                                post.frontmatter.img.childImageSharp.gatsbyImageData.src
                              }
                              alt=""
                          />
                        </figure>
                      </div>
                  )}
                  <div className="wrap-content">
                    <header className="header-page">
                      <h1 className="page-title">{post.frontmatter.title}</h1>
                      <div className="page-date">
                        <span>{post.frontmatter.date}</span>
                      </div>
                    </header>
                    <div dangerouslySetInnerHTML={{__html: post.html}}/>

                    <div className="next-previous">
                      <div className="previous">
                        <Link to={previous.fields.slug}>
                          <strong>Previous Article</strong> <br/>
                          {previous.frontmatter.title}
                        </Link>
                        <Link className="img-link" to={previous.fields.slug}>
                          <img src={previous.frontmatter.img.childImageSharp.gatsbyImageData.images.fallback.src}/>
                        </Link>
                      </div>
                      <div className="next">
                        <Link to={next.fields.slug}>
                          <strong>Next Article</strong> <br/>
                          {next.frontmatter.title}
                        </Link>
                        <Link className="img-link" to={next.fields.slug}>
                          <img src={next.frontmatter.img.childImageSharp.gatsbyImageData.images.fallback.src}/>
                        </Link>
                      </div>
                    </div>

                    <div className="page-footer">
                      <div className="page-tag">
                        {post.frontmatter.tags &&
                        post.frontmatter.tags.map((tag) => (
                            <span key={tag}>
                          <Link className="tag" to={`/tags/${kebabCase(tag)}/`}>
                            # {tag}
                          </Link>
                        </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </DefaultLayout>
      )
    }
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY, MMM DD")
        tags
        img {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, formats: [AUTO, AVIF, WEBP])
          }
        }
      }
    }
  }
`
